import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DataTable, Pagination, Modal } from '../../secondary';
import { Confirmation } from '../../secondary/Confirmation/confirmation'
import { TextField, Button, SelectBox, RadioButton, AccessErrorModal } from '../../primary';
import Loader from '../../../static/images/spinner.svg';
import { form_group } from '../../../styles/common.module.scss';
import DeleteIcon from '../../../static/images/delete-icon.svg';
import searchIcon from '../../../static/images/search-icon.svg';
import PlusIcon from '../../../static/images/plus-icon_white.svg';
import warning from '../../../static/images/icon-check-danger.png';
import { CreatePatients } from '../../core/Patients/'
import { CreateCustomer } from '../../core/Customers/'
import { CreateUser } from '../../core/Users/'
import { CsvImportLoader } from '../../core/Patients/csvImportLoader'
import style from './listView.module.scss';
import { Header } from '../Header';
import { SimpleCreatePatient } from '../../core/Patients/simpleCreatePatient';
import { CsvImport } from '../CsvImport';
import { CreateForm } from '../../core/FormBuilder/'
import { CreateAnnouncement } from '../../core/Announcement/';
import { CreateListViewMenu } from '../../core/Layouts';
import { getFacilityLabel, useDebounce } from '../../../utils';
import { CreateProductMaterial } from '../../core/Products';
import { resetFacilitiesData, getNewFacilityDataAsync, getFacilityDataAsyncForPatients, getCallBackListAsync,setFacilityAndCustomerNumLastVal, setAdminView } from '../../../app/patientSlice';
import { useForm } from 'react-hook-form';
import { LIST_VIEW_LIMIT, PATIENT, DELETE_ASSESSMENT, DELETE_HISTORY_ASSESSMENT, SUCCESS_STATUS } from '../../../constants';
import rightArrow from '../../../static/images/right_arrow.svg';
import { id } from 'date-fns/locale';
import { CreateSso } from '../../core/Users/createSso';
import {getFaciltiestUserAsyn, setUserFacilities, setCustomerNumberSearch, getCustomerNumberSuggestions} from '../../../app/userSlice'


export let ListView = ({ location, dispatch, history, columns, data, metaData, sorting,
  rowSelect, getSelectedRowData, detailView, searchPlaceholder, getSearchValue, detailsRow,
  activePage, getActivePage, listSorting, listType, subHeading, facilityListView, fileUplodad,
  showFixedColumn, setUploadStatus, uploadingCheckAsync, deleteListView, searchKey, contextualSupportView,
  selectedFacility, getPatientList, setCreateFormulary, formularyView, getSelectedItems, noPage, formularyLeft,
  handleProductChange, productType, setProductType, rightFormulary, addedItems, sortingItems, fixedFullWidthSize, offset, getCustomerList, getUserList,
  accessError, otherHeading, setCustomerNumber, sortType, facilityNumberOption, setFacilityNumber, pendingList, lockedList, openPend, openLock,patientListLastVal, manualPatientEnable,getSearchValueCust,
  handleSearchColumn,searchColumns,setSearchColumns,setSearchKey, setActivePage, setFormularyNamePopup, filterOnclick, clickImportExcel
}) => {
  const patientLoad = useSelector(state => state.patient.patientLoad);
  /* Get url of the current page */
  const path = window.location.href;
  /* Below will return only domain - http://www.google.com */
  const origin = window.location.origin;
  
  const isAdminView = path.includes(`${origin}/admin`);

  if(path.includes(`${origin}/admin`)){
    dispatch(setAdminView('admin'))
  }else{
    dispatch(setAdminView('not'))
  }
  const [showClearX, setShowClearX] = useState(searchKey?true:false)
  useEffect(() => {
    if (searchKey) {
      setShowClearX(true)
    }else{
      setShowClearX(false)
    }
  },[searchKey]);
  const [modalShow, setmodalShow] = useState(false)
  const [menuShow, setMenuShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("")
  const [onselectOrgz, setonselectOrg] = useState([]);
  const userRole = useSelector(state => state.user);

  // const facilityAndCustomerNumLastVal = useSelector(state => state.patient.facilityAndCustomerNumLastVal)



  const { register, handleSubmit, control, errors, reset, setError, watch } = useForm();
  const loading = useSelector(state => state.app.loading)
  // const adminView = useSelector(state => state.patient.adminView)

  const dropRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleClickOutside = (e) => {
    if (dropRef !== null && dropRef.current && !dropRef.current.contains(e.target)) {
      setMenuShow(false);      
    }
  };

  const menuList = () => {
    setMenuShow(true)
  }
  const modalOpen = () => {
    setmodalShow(true);
  }

  const modalClose = () => {
    setmodalShow(false);
    setConfirmationModal(false);    
  }

  // const getFacilities = useSelector(state => state.patient.facilitiesData);
  const getFacilities = useSelector(state => state.patient.newFacilitiesData);
  const [facOptionData, setFacOptionData] = useState('');


  // Resetting to blank when navigating between 
  // useEffect(() => {
  //   if(adminView === 'admin' && path.includes(`${origin}/admin`)){
  //     dispatch(setFacilityAndCustomerNumLastVal({customerNumber: ''}))
  //   }else if(adminView !== 'admin' && !path.includes(`${origin}/admin`)){
  //     dispatch(setFacilityAndCustomerNumLastVal({customerNumber: ''}))      
  //   }
  // }, [adminView])

  useEffect(() => {
    let fac = ""
    if (getFacilities?.errorMessage || getFacilities?.length === 0 &&document.getElementById('customerNumberId')&&document.getElementById('customerNumberId').value) {
      fac = "We didn’t recognize this customer number. Enter a different customer number"
    }
    setErrorMsg(fac)
    setFacOptionData(facilityNumberOption)
  }, [getFacilities,facilityNumberOption])

  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const selectRowCount = (length) => {
    setSelectedRowCount(length)
  }

  let selectedData = "";
  const deleteDataList = (data) => {
    selectedData = data
    if (formularyView) {
      getSelectedItems(data)
    }

  }

  const deleteList = () => {
    deleteListView(selectedData);
    setConfirmationModal(false)
  }

  const [confirmationModal, setConfirmationModal] = useState(false)
  const deleteModal = () => {
    setConfirmationModal(true)
  }

  const handleSort = useCallback(data => {
    if (listSorting) {
      let sortingColumn = data.sortBy[0]
      let sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
      listSorting['queryParam']['sort'] = (sortingColumn) && sortingColumn.id + sortingType
      // The below if condition is for 'Sorting' + 'Pagination'  
      if (listSorting.setSortType) {
        //dispatch(listSorting.setSortType(listSorting['queryParam']['sort']))
        if(listSorting['queryParam']['sort']==undefined&&sortType){
          listSorting['queryParam']['sort']=sortType;
        }else{
          if(listSorting['queryParam']['sort']&&listSorting['queryParam']['sort']!==undefined){
            listSorting.setSortType(listSorting['queryParam']['sort'])
          }
        }
      }
      if(listType === 'Customers' &&Array.isArray(data?.sortBy)&&data?.sortBy.length>0){
        dispatch(listSorting.asyncMethod(listSorting));
      }else if(listType !== 'Customers'&& sortingColumn){
        dispatch(listSorting.asyncMethod(listSorting));
      }
    }

  }, [dispatch, searchKey, activePage, offset, sortType, searchColumns,patientLoad])

  const handleRightSort = useCallback(data => {
    if (listSorting) {
      let sortingColumn = data.sortBy[0]
      let sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
      listSorting['queryParam']['sort'] = (sortingColumn) && sortingColumn.id + sortingType
      if (!formularyView) {
        dispatch(listSorting.asyncMethod(listSorting));
      }
      if (formularyView) {
        listSorting.asyncMethod(sortingColumn)
      }
    }
  }, [dispatch, addedItems])

  const handleProductSort = useCallback(data => {
    if (listSorting) {
      let sortingColumn = data.sortBy[0]
      let sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
      listSorting['queryParam']['sort'] = (sortingColumn) && sortingColumn.id + sortingType
      if (listSorting.setSortType) {
        if(listSorting['queryParam']['sort']==undefined&&sortType){
          listSorting['queryParam']['sort']=sortType;
        }else{
          if(listSorting['queryParam']['sort']&&listSorting['queryParam']['sort']!==undefined){
            listSorting.setSortType(listSorting['queryParam']['sort'])
          }
        }
      }
      dispatch(listSorting.asyncMethod(listSorting));
    }
  }, [dispatch, productType, activePage, searchKey, offset,sortType])

  const upDateData = (data, id, value) => { }


  /* CSV file imoport block */

  /* Aborting upload */
  const controller = new AbortController()
  const signal = controller.signal

  /* Cancel upload function */
  const cancelUpload = () => {
    // Abort.
    controller.abort()
    setFileSelected(false)
    dispatch(setUploadStatus(null))
  }

  /* State to check weather the CSV file is selected or not */
  const [fileSelected, setFileSelected] = useState(false)

  /* Handle file import */
  const handleImport = (data) => {
    /* If file selected, set state as true */
    /* Check if the selected file is CSV or not */
    const fileName = data.target.files[0].name.split('.')
    if (fileName[fileName.length - 1] === 'csv'||fileName[fileName.length - 1] === 'xlsx'||fileName[fileName.length - 1] === 'xls') {
      setFileSelected(true)
      /* Initilize formdata */
      let payload = new FormData();
      // Add file to formdata
      payload.append(
        'csv',
        data.target.files[0]
      );
      /* Dispatch payload and abort signal */
      dispatch(fileUplodad({ payload, signal, getPatientList, getCustomerList, getUserList }))
      /* Reset the form */
      data.target.value = ''
    } else {
      /* If it is not a csv file error */
      // alert('Wrong file type selected!!!')
      alert('The import file must be either Excel (xls) or CSV (csv) format')
    }
  }

  const [getFormImport, setGetFormImport] = useState()
  const handleImportFormulary = (data) => {
    /* If file selected, set state as true */
    /* Check if the selected file is CSV or not */
    const fileName = data.target.files[0].name.split('.')
    if (fileName[fileName.length - 1] === 'csv'||fileName[fileName.length - 1] === 'xlsx'||fileName[fileName.length - 1] === 'xls') {
      setFileSelected(true)
      /* Initilize formdata */
      let payload = new FormData();
      // Add file to formdata
      payload.append(
        'csv',
        data.target.files[0]
      );
      /* Dispatch payload and abort signal */    
      // setGetFormImport(payload)
      dispatch(fileUplodad({ payload, signal, getPatientList, getCustomerList, getUserList }))      
      /* Reset the form */
      data.target.value = ''
    } else {
      /* If it is not a csv file error */
      // alert('Wrong file type selected!!!')
      alert('The import file must be either Excel (xls) or CSV (csv) format')
    }
  }

  const handleUploadFormulary = () => {
    // dispatch(fileUplodad({ getFormImport, signal, getPatientList, getCustomerList, getUserList }))
  }

  /* Loader close */
  const loaderClose = () => {
    setFileSelected(false)
    dispatch(setUploadStatus(null))
  }

  /* Check weather someone else is uploading */
  const [uploading, setUploading] = useState(false)
  const uploadingPatient = useSelector((state) => state?.patient?.uploading)
  const uploadingCustomer = useSelector((state) => state?.customer?.uploading)
  const uploadingUSer = useSelector((state) => state?.user?.uploading) 

  useEffect(()=>{
    if(path=== `${origin}/admin/patients`){
      setUploading(uploadingPatient)
    }
    if(path=== `${origin}/admin/customers`){
      setUploading(uploadingCustomer)
    }
    if(path=== `${origin}/admin/users`){
      setUploading(uploadingUSer)
    }
  },[path])

  window.onload = function() {  
    if(!fileSelected) {
      if (path === `${origin}/admin/patients` || path === `${origin}/admin/customers` || path === `${origin}/admin/users`) {
        dispatch(uploadingCheckAsync())
      }      
    }
  }

  useEffect(()=>{
    if(!fileSelected) {
      if (path === `${origin}/admin/patients` || path === `${origin}/admin/customers` || path === `${origin}/admin/users`) {
        dispatch(uploadingCheckAsync())
      }      
    }
  },[path])
  
  /* For set interval timer */
  const [intervalId, setIntervalId] = useState(0) 
  useEffect(() => {
    /* If no file is selected keep on calling API to check if someone is uploading,
      if someone is uploaidng, we need to disable the "import" button */
    let interval;
    // if (!fileSelected) {
    //   if (path === `${origin}/admin/patients` || path === `${origin}/admin/customers` || path === `${origin}/admin/users`) {
    //     interval = setInterval(() => {
    //       dispatch(uploadingCheckAsync())
    //     }, process.env.REACT_APP_UPLOADING_CHECK_INTERVAL)
    //     /* Assigning it to state */
    //     setIntervalId(interval)
    //   }
    // }

    /* If not in list view clear interval */
    if (path !== `${origin}/admin/patients` || path !== `${origin}/admin/customers` || path !== `${origin}/admin/users`) {
      clearInterval(intervalId)
    }
    /* If we are uploading, we should not call above API */
    if (fileSelected) {
      clearInterval(intervalId)
    }
    return () => {
      clearInterval(interval)
    }
  }, [fileSelected, dispatch])

  let listModalBox = {
    'Patients': isAdminView ? <CreatePatients modalOpen={modalShow} modalClose={modalClose} facilityListView={facilityListView} /> :
      <SimpleCreatePatient modalOpen={modalShow} modalClose={modalClose} selectedFacility={(selectedFacility) ? selectedFacility : ''} getPatientList={getPatientList} facilityListView={facilityListView}/>,
    'Customers': isAdminView ? <CreateCustomer modalOpen={modalShow} modalClose={modalClose} setSearchColumns={setSearchColumns} setSearchKey={setSearchKey} setActivePage={setActivePage}/> : '',
    'Users': isAdminView ? <CreateUser modalOpen={modalShow} modalClose={modalClose} /> : '',
    'Sso': isAdminView ? <CreateSso modalOpen={modalShow} modalClose={modalClose} /> : '',
    'FormBuilder': <CreateForm modalOpen={modalShow} modalClose={modalClose} />,
    'Announcement': <CreateAnnouncement modalOpen={modalShow} modalClose={modalClose} />
  }

  let headerProp = {
    'Patients': {
      heading: `${isAdminView ? otherHeading ? 'Patient Management' : 'Admin Portal' : 'Patients'}`,
      // subHeading: `${isAdminView ? 'Patient Management' : ''}`
    },
    'Customers': {
      heading: 'Admin Portal',
      subHeading: subHeading
    },
    'Users': {
      heading: 'Admin Portal',
      subHeading: 'Users Profile'
    },
    'Sso': {
      heading: 'Admin Portal',
      subHeading: 'SSO'
    },
    'FormBuilder': {
      heading: 'Admin Portal',
      subHeading: 'Form Builder'
    },
    'Announcement': {
      heading: 'Admin Portal',
      subHeading: 'Announcement'
    },
    'Layouts': {
      heading: 'Admin Portal',
      subHeading: 'Layout'
    },
    'ContextualSupport': {
      heading: 'Admin Portal',
      subHeading: 'Contextual Support'
    },
    'Formulary': {
      heading: 'Admin Portal',
      subHeading: 'Formulary'
    },
    'Products': {
      heading: 'Admin Portal',
      // subHeading: (productType === "material") ? 'Material' : (productType === "productfamily") ? 'Product Family' :''
      subHeading: 'Products'
    },
  }

  let listMenu = {
    'Layouts': <CreateListViewMenu menuList={menuShow} dropRef={dropRef} />
  }
  let productMaterialCreateMenu = {
    'Products': <CreateProductMaterial menuList={menuShow} dropRef={dropRef} setProductType={setProductType}/>
  }

  const getCustomerNumber = (value) => {
    if (value) {
       setErrorMsg('')
      // setonselectOrg([])
     // setCustomerNumber(value)
      dispatch(getFacilityDataAsyncForPatients({ customerNumber: value},setCustomerNumber));
      // dispatch(setFacilityAndCustomerNumLastVal({
      //   customerNum  : value,
      //   facilitylocation : facilityAndCustomerNumLastVal.facilitylocation ? facilityAndCustomerNumLastVal.facilitylocation  : ''
      // }))setFacilityAndCustomerNumLastVal
    }
  }
  
  const imagePlusIcon = <img src={PlusIcon} className={`${style.plusimg}`} alt="Click to add" />

  const getShowClearX = (e) => {
    if (e?.target?.value) {
      setShowClearX(true)
      return e.target.value=e?.target?.value.replace(/(\s{2,})/g, ' ').replace(/^\s*/, '')
    }else{
      setShowClearX(false)
    }
  }
  const [showClearXCust, setShowClearXCust] = useState(patientListLastVal?.customerNumber?true:false)
  const getShowClearXCust = (e) => {
    if (e?.target?.value) {
      setShowClearXCust(true)
      return e.target.value=e?.target?.value.replace(/(\s{2,})/g, ' ').replace(/^\s*/, '')
    }else{
      setShowClearXCust(false)
    }
  }

  const [showFormOption, setShowFormOption] = useState(false)
  const [excelHoverOption, setExcelHoverOption] = useState(false)
  const [createManualOption, setCreateManualOption] = useState(false)
  const onClickFormulary = () => {
    setShowFormOption(showFormOption => !showFormOption);
  }

  const formularyRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideForumulary);
    return () => document.removeEventListener('mousedown', handleClickOutsideForumulary);
  });

  const handleClickOutsideForumulary = (e) => {
    if (formularyRef !== null && formularyRef.current && !formularyRef.current.contains(e.target)) {
      setShowFormOption(false);      
    }
  };

  const [showImportPopup, setShowImportPopup] = useState(false)
  // const clickImportExcel = () => {
  //   setShowImportPopup(showImportPopup=> !showImportPopup)
  // }

  const [showPopup, setShowPopup] = useState(false)
  const clickManualCreation = () => {
    setShowPopup(true)
  }

  // const saveFormularyName = () => {
  //   setCreateFormulary(true)
  //   setFormularyNamePopup(document.getElementById('formularyName').value)
  // }
   /* CustomerNumber search Suggestions  */   
   const [custNumInput, setCustNumInput] = useState("");
   const [isSelect, setIsSelect] = useState(false);
   const searchTerm = useDebounce(custNumInput, 500);
   const [errorMsgFor, setErrorMsgFor] = useState("")
   const [orgError, setOrgError] = useState(false)

   const customerNumberSearchDatas = useSelector(
       (state) => state.user.customerNumberSearch
     );

 
   useEffect(() => {
       if (!isSelect && searchTerm && searchTerm.length >= 1) {
         dispatch(getCustomerNumberSuggestions(searchTerm));
       } else {
         dispatch(setCustomerNumberSearch({}));
       }
     }, [searchTerm]);

     const [checkOrgDrop, setCheckOrgDrop] = useState(false)
     const getCustomerNumberFormulary = (val) => {         
      dispatch(setUserFacilities([])) 
  
      if(val === ''){
        setErrorMsgFor("please enter any account number")
      }
      else{ 
          setErrorMsgFor('')     
          const payload = { customer: val }
          dispatch(getFaciltiestUserAsyn(payload,setCheckOrgDrop,setErrorMsgFor));                   
      }       
  }

  const getCustomerNumberSearch = (event) => {  
    if(event === ''){
        setErrorMsgFor("please enter any account number")
        setCustNumInput("")
    }else {    
        setErrorMsgFor('')   
        setIsSelect(false);    
        setCustNumInput(event)                 
    }  
  };  

  const customerNumberSelect = (e, items) => {
    e.stopPropagation();  
    setIsSelect(true);
    dispatch(setCustomerNumberSearch({}));              
    if (items) {
      setErrorMsgFor('')       
      const customerNumber = items;
      setCustNumInput(customerNumber);
      document.getElementById("formularyName").value = customerNumber;
      document.getElementById("formularyName").value = items; 
      setFormularyNamePopup(customerNumber)              
      const payload = { customer: items }
      dispatch(getFaciltiestUserAsyn(payload, setCheckOrgDrop));       
    }      
  }



//   const ClearData = (value) => { 
//     document.getElementById(CUSTOMER_NUMBER_ID).value = null ;        
//     setonselectOrg([]);
//     setCheckOrgDrop(false) 
//     setToDo('')
//     dispatch(setUserFacilities([])) 
//     setCustNumInput("");
//     setDropdownOpen(false)
// }

  return <>
    {!formularyView && <Header {...headerProp[listType]} detailsRow={detailsRow} />}
    <div className={`col pt-0 pr-0 pb-3`}>
      <div className={`row ${style.ipadm_l}`}>
        {!formularyView && <>
        
          {(listType == 'Customers')&&
            <SelectBox
                id={"searchSelectbox"}
                name={"searchSelectbox"}
                classes={{
                    column: `col-15 h-33 w-175 ${style.col_spec}`,
                    inputClassName: style.searchBarSelect,
                    inputColumn: `col-15 p-0 ${style.searchPos}`
                }}
                optionValue={[
                { key: 'customerNumber', value: 'Customer #' },
                { key: 'customerName', value: 'Customer name' },
                ]}
              //selectedData={searchColumns.includes(columnDatas)}
              //searchColumns
                defaultValue={searchColumns?searchColumns:''}
                onChange = {(e) => handleSearchColumn(e)}
          />
        }
        <TextField
          classes={{
            column: `form-group no-gutters ${form_group} position-relative ${listType === 'Patients' ? 'col-sm-4' : 'col-sm-7'} p-0`,
            inputClassName: `form-control mb-1 ${style.pl40}`,
            labelClass: `position-absolute ${style.search_icon_image}`
          }}
          label={<img src={searchIcon} alt="search icon" />}
          type={"text"}
          name={"search"}
          id={"search"}
          placeholder={searchPlaceholder}
          title={'Toggle All Rows Selected'}
          onKeyUp={getSearchValue}
          defaultValue={searchKey?searchKey.trim():searchKey}
          getSearchValue={getSearchValue}
          onChange={getShowClearX}
          showClearX={showClearX}
          setShowClearX={setShowClearX}

        />
         
          {(listType === 'Products') &&
            <div className="ml-3" key={productType}>
              <RadioButton
                id={"productType"}
                name={"productType"}
                classes={{
                  labelClass: `custom-control-label col-form-label ${style.custom_control_label}`,
                  inputClassName: `custom-control-input ${style.custom_control_input} productType`,
                  inputColumn: `custom-control custom-radio custom-control-inline`
                }}
                radioOption={['Product Family','Material']}
                defaultValue={(productType && productType === 'material') ? 'Material' : 'Product Family'}
                onChange={handleProductChange}
              />
            </div>
          }     
          {/* Pending & Locked Button */}     
          {(listType === 'Users') && <div className='ml-3'>   
            <button
                id={"pending-btn"}
                key={'index0'}
                className = {openPend === true ? ` ${style.btn_addextrahover} mr-3 toggleBtn` : ` ${style.btn_addextra} mr-3 toggleBtn`}
                onClick={() => pendingList()}
                value="A-D"
              >
                Pending Approval
            </button>
            <button
                id={"locked-btn"}
                key={'index1'}
                className = {openLock === true ? ` ${style.btn_addextrahover} toggleBtn` : ` ${style.btn_addextra} toggleBtn`}
                onClick={() => lockedList()}
                value="E-H"
              >
                Account Locked
            </button>                
          </div>} 
          {/* Pending & Locked Button */}

        </>
        }

        <div className={`${((productType === 'productfamily')) ? 'col-sm-12 justify-content-end' : ((productType === 'material') && Array.isArray(data) && data.length === 0) ? 'col-sm-12' : (listType === 'Patients') ? 'col-sm-8' : 'col-sm-5 justify-content-end'} d-flex pr-0`}>
          {listType === "Patients" && <div className={`${style.customer_search_parent}`}>
            <div>
              <div className={`d-flex`}>
                <SelectBox
                  id={"facility"}
                  name={"facilityFilter"}
                  classes={{ column: `${listType === 'Patients' ? `pl-0 ${style.facility_pos} col-9` : "col-8 pl-0"}`, labelClassName: `col-3 col-form-label p-0 `, inputColumn: `col-12 p-0  ${style.select_wrapper}`, inputClassName: `form-control ${style.basic_select}` }}
                  optionValue={Array.isArray(facOptionData)?facOptionData:[]}
                  //defaultValue = {selectedFacility}
                  defaultValue={patientListLastVal?.selectedFacility?patientListLastVal?.selectedFacility:selectedFacility}
                  onChange={(e) => facilityListView(e.target.value)}
                  placeholder={'Facility/Location'}
                />
                {
                  !isAdminView && <Button
                    id={"forgot-btn"}
                        // classes={{ className: (listType !== 'Patients') ? `btn ${style.btn_add}` : manualPatientEnable ? `btn ${style.btn_add}` :  `btn ${style.btn_add} ${style.disableButton}`}}
                    classes={{ className: `btn ${style.btn_add}` }}
                    // Added condition for formulary create page on click of this button
                        // onClick={(listType !== 'Patients') ? () => modalOpen() : manualPatientEnable ? () => modalOpen() : ""}
                    onClick={() => modalOpen()}
                    ref={dropRef}
                  >
                    {imagePlusIcon}
                    {(listType == 'Patients') ? 'Add Patient' : ''}
                  </Button>
                }
              </div>
            </div>
          </div>
          }
        </div>
      </div>
      {/* {(userRole?.role !== 'ExternalAdministrator') && <div className={ userRole?.role === 'ExternalAdministrator' ? `w-100 ${style.min_height_external}` : `w-100 ${style.min_height}`}> */}
      {<div className={userRole?.role === 'ExternalAdministrator' ? `w-100 ${style.min_height_external}` : `w-100 ${style.min_height}`}>
          {Array.isArray(data) && data.length > 0 && isAdminView && !contextualSupportView && !formularyView && (productType !== 'productfamily') && listType !== "Patients" &&  <div className={userRole?.role !== 'ExternalAdministrator' ? `pt-4 d-inline-block` : `pt-4 d-inline-block mb-3`}>
            {<span id='selectedRowCount' className={userRole?.role !== 'ExternalAdministrator' ? 'font-weight-bold  pl-4': 'd-none'}>{`${selectedRowCount} Selected`}</span>}
            {selectedRowCount > 0 && userRole?.role !== 'ExternalAdministrator' && <div className={style.del_block} onClick={() => deleteModal()}>
              <img src={DeleteIcon} alt="delete" id="delete_icon" />Delete
            </div>}
          </div>}
          {Array.isArray(data) && data.length > 0 && isAdminView && listType === "Patients" &&  <div className={`pt-4 d-inline-block`}>
            {<span id='selectedRowCount' className='font-weight-bold  pl-4'>{`${selectedRowCount} Selected`}</span>}
            {selectedRowCount > 0 && <div className={style.del_block} onClick={() => deleteModal()}>
              <img src={DeleteIcon} alt="delete" id="delete_icon" />Delete
            </div>}
          </div>}
          {Array.isArray(data) && data.length > 0 && isAdminView && productType === "productfamily" && <div className={`pt-4 d-inline-block`}>
            <div></div>
          </div>}
          <div className="d-inline-block float-right">
            <div className="d-flex">
              {/* Added condition not to display import button for formulary */}
              {/* File import, first checking weather the role of the user */}
              {isAdminView && userRole?.role !== 'ExternalAdministrator' && !contextualSupportView && listType !== 'FormBuilder' && listType !== 'Announcement' && listType !== 'Layouts' && listType !== 'Formulary' && listType !== 'Products' && listType !== 'Sso' ? <CsvImport uploading={uploading} handleImport={handleImport} /> : ''}

              {/* If we started uploaing display the modal with loader */}
              {fileSelected && userRole?.role !== 'ExternalAdministrator' && !contextualSupportView && listType !== 'FormBuilder' && listType !== 'Announcement' && listType !== 'Layouts' && listType !== 'Formulary' && listType !== 'Products' && listType !== 'Sso' && <CsvImportLoader modalOpen={fileSelected} modalClose={loaderClose} cancelUpload={cancelUpload} listType={listType} />}
              <div className="createitem_block" ref={formularyRef}>
                <div className="btn-group">
                  {!contextualSupportView && !formularyView && isAdminView &&
                    <Button
                      id={"forgot-btn"}
                      classes={{ className: userRole?.role !== 'ExternalAdministrator' ? `btn ${style.btn_add}` : `btn ${style.btn_add} mb-3` }}
                        // classes={{ className: userRole?.role !== 'ExternalAdministrator' ? listType !=="Patients" ? `btn ${style.btn_add}` : manualPatientEnable ? `btn ${style.btn_add}` : `btn ${style.disableButton}` : `btn ${style.btn_add} mb-3` }}
                      // Added condition for formulary create page on click of this button
                        // onClick={(listType == 'Products') ? () => menuList() : (listType == 'Layouts') ? () => menuList() : listType === 'Formulary' ? () => setCreateFormulary(true) : listType !== 'Patients' ? () => modalOpen() : manualPatientEnable ? () => modalOpen() : ""}
                      onClick={(listType == 'Products') ? () => menuList() : (listType == 'Layouts') ? () => menuList() : listType === 'Formulary' ? () => setCreateFormulary(true) : () => modalOpen()}
                      // onClick={(listType == 'Products') ? () => menuList() : (listType == 'Layouts') ? () => menuList() : listType === 'Formulary' ? () => onClickFormulary() : () => modalOpen()}
                      ref={dropRef}                  
                    >
                      {imagePlusIcon}
                      {(listType == 'Patients') ? 'Add Patient' : ''}
                      {(listType == 'Customers') ? 'New Customer' : ''}
                      {(listType == 'Users') ? 'Create User' : ''}
                      {(listType == 'Sso') ? 'New' : ''}
                      {(listType == 'FormBuilder') ? 'Create Form' : ''}
                      {(listType == 'Announcement') ? 'Create Item' : ''}
                      {(listType == 'Layouts') ? 'Create New' : ''}
                      {(listType == 'Formulary') ? 'New Formulary' : ''}
                      {(listType == 'Products') ? 'New ' : ''}
                    </Button>
                  }
                  <div>
                    {
                      listMenu && <div>{listMenu[listType]}</div>
                    }
                  </div>
                  <div>
                    {
                      productMaterialCreateMenu && <div>{productMaterialCreateMenu[listType]}</div>
                    }
                  </div>
                </div>                
                
                {showFormOption && <div className={`${style.formularySelectionList}`}>
                    <div 
                      className={`${style.importExcelSection}`} 
                      onMouseOver={()=>{setExcelHoverOption(true)}} 
                      onMouseLeave={()=>{setExcelHoverOption(false)}}
                      onClick={()=>clickImportExcel()}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 17.906 16.853">
                          <path d="M14.684 56.77a2.607 2.607 0 0 0-.991-.612v4.056h4.056a2.583 2.583 0 0 0-.612-.99l-2.453-2.454zm-2.044 4.5V56H4.213v8.426H0v1.054h4.213v7.373h13.693V61.267H12.64zm-4.367 7.5-.745-.745 2.545-2.546H5.267v-1.053h4.806l-2.545-2.544.745-.745 3.816 3.816-3.816 3.817z" transform="translate(0 -56)" style={{fill: !excelHoverOption ? '#505f79' : '#ffffff'}}/>
                      </svg>
                      <p style={{alignItems:'baseline', paddingTop:'1px', paddingLeft:'5px', fontSize: '15px'}}>Import from excel </p>                        
                    </div>
                    <div 
                      className={`${style.createManualSection}`} 
                      onMouseOver={()=>{setCreateManualOption(true)}} 
                      onMouseLeave={()=>{setCreateManualOption(false)}}
                      onClick={()=>clickManualCreation()}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 19.943 19.94">
                          <path data-name="Union 1" d="M0 19.94V3.307h13.02L6.493 9.821l-.444.443v3.627h3.627l.443-.444 6.514-6.527v13.02zm7.56-7.56v-1.485l7.6-7.585 1.552-1.549 1.469 1.469-1.55 1.553-7.584 7.6zm9.743-11.213.865-.862a1.043 1.043 0 0 1 .761-.3 1.043 1.043 0 0 1 .746.34 1.047 1.047 0 0 1-.048 1.45l-.851.846z" style={{fill: !createManualOption ? '#505f79' : '#ffffff'}}/>
                      </svg>
                      <p style={{alignItems:'baseline', paddingTop:'1px', paddingLeft:'5px', fontSize: '15px'}}>Create manually </p>                                                         
                    </div>
                  </div>}

              </div>
            </div>
          </div>
      </div>
    }      
    </div>

    {showImportPopup && 
      <Modal
        modalOpen={showImportPopup}
        modalClose={()=>{setShowImportPopup(false)}}
        heading={"Import Formulary"}
        className={"modal_box"}
        modalSize={style.custom_modal_size_import}
        custom_heading={style.custom_modal_size_import_heading}
        reset={reset}
      >
        <div className={`${style.import_top_content} mt-3`}>
          <div className='pt-5 pb-4'>
            <span>
              <i class="fa fa-plus" aria-hidden="true" style={{color:'#0052cc', fontSize:'30px'}}></i>
            </span>
          </div>
          <div className='py-5'>
            <button
              className={`${style.btn_browse}`}
              type="button"
              // onClick={()=>saveFormularyName()}
              // onClick={() => handleImport()}
              >
              <label htmlFor="csv">Browse</label>
              <input
              type="file"
              id="csv"
              className={style.file_upload}
              accept=".csv, .xls, .xlsx, text/csv, application/csv,
                    text/comma-separated-values, application/csv, application/excel,
                    application/vnd.msexcel, text/anytext, application/vnd. ms-excel,
                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={handleImportFormulary}
            />
            </button>                    
          </div>          
        </div>
        <div className={`${style.import_bottom_content} d-flex justify-content-around align-items-center`}>              
            <div className=''>
              <Button
                classes={{
                  className: `btn ${style.btn_cancel_import}`,
                }}
                type="cancel"
                // onClick={()=>saveFormularyName()}
                onClick={() => {setShowImportPopup(false)}}
              >
                {"Cancel"}
              </Button>                    
            </div>
            <div className=''>
              <Button
                classes={{
                  className: `btn ${style.btn_save_import}`,
                }}
                type="save"
                onClick={handleUploadFormulary}
              >
                {'Upload'}
              </Button>
            </div>
          </div>
      </Modal>  
    }

    {showPopup && 
      <Modal
          modalOpen={modalOpen}
          // modalClose={modalClose}
          // heading={"Create Item"}
          className={"modal_box"}
          modalSize={style.custom_modal_size}
          reset={reset}
      >
        <div className={`${style.createManualPopup}`}>
            {/* <h1>Enter the account number</h1> */}
            <h1>Enter the account number</h1>

            <div className="col-8 m-auto text-center py-4">
                <TextField
                  classes={{
                    column: `form-group col-12 p-0 ${style.field_max}`,
                    labelClass: `font-weight-bold ${style.label_spec}`,
                    inputClassName: "form-control mb-1",
                    inputColumn: "col-12 p-0",
                  }}
                  // label={"Formulary Name:"}
                  type={"text"}
                  name={"formularyName"}
                  id={"formularyName"}
                  // placeholder={"Account Number"}
                  // validator={register({
                  //   required: "Please enter formulary name",
                  // })}                          
                    errors={errors}
                    customError={errorMsgFor ? errorMsgFor : ""}
                    // customError={
                    //     editState?.errorMessage && errCk?.data===null
                    //       ? editState.errorMessage
                    //       : customerNumberSearchDatas &&
                    //         Array.isArray(customerNumberSearchDatas) &&
                    //         customerNumberSearchDatas.length === 0
                    //       ? "Customer Number is not found"
                    //       : ""
                    //   }
                    required={true}
                    validator={register()} 
                    // validator={register({
                    //     validate: (value,e) => {
                    //       if ((value === ""|| value === null)&&(traveNurse === 0)) {
                    //         return "Customer Number Required"
                    //       } 
                    //       return true                             
                    //     }
                    //   })}                       
                    // defaultValue={toDo}                                                   
                    onKeyPress={()=>{
                        setErrorMsgFor('')
                    }}
                    onChange={(e)=>getCustomerNumberSearch(e.target.value)}                        
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          getCustomerNumberFormulary(e.target.value);
                        }
                    }} 
                />
                {customerNumberSearchDatas &&
                    Array.isArray(customerNumberSearchDatas) &&
                    customerNumberSearchDatas.length > 0 && (
                        <div className="col-12 col-spec row">
                          <div className={`col_spec mr-auto ${style.search_drop}`}>
                            <div className={`col-spec ${style.search_close_box}`}>
                            {customerNumberSearchDatas.map(
                                (items, index) =>
                                items && (
                                    <p
                                    className={`dropdown-item`}
                                    title={items.value}
                                    onClick={(e) => customerNumberSelect(e, items)}
                                    >
                                    {items}
                                    </p>
                                )
                            )}
                            </div>
                        </div>
                        </div>
                    )} 
              </div>
              <div className='pt-1 pb-3'>
                <Button
                  classes={{
                    className: `btn ${style.btn_custom_continue}`,
                  }}
                  type="Save"
                  // onClick={()=>saveFormularyName()}
                  onClick={() => {setCreateFormulary(true)}}
                >
                  {"Continue"}
                </Button>                    
              </div>
              <div className=''>
                <Button
                  classes={{
                    className: `btn ${style.btn_custom}`,
                  }}
                  type="button"
                  onClick={() => setShowPopup(false)}
                >
                  {'Cancel'}
                </Button>
              </div>
        </div>
        
      </Modal>
    }
{
      patientLoad && listType == 'Patients'?<div style={{ minHeight: '300px',display:'flex',justifyContent:'center' }} className={`text-center mt-2 mb-3`}>
      <img src={Loader} alt="loader" style={{ maxWidth: '50px' }} />
    </div>:<>
    
    {
      data && listType == 'Patients'&& <>
        <div className={`${style.loader_parent}`}>
          {/* {loading && <div className={`text-center mt-2 mb-3 ${style.loader_child}`}>
            <img src={Loader} alt="loader" style={{ maxWidth: '50px' }} />
          </div>} */}
          <DataTable
            columns={columns}
            data={data}
            sorting={sorting}
            rowSelect={rowSelect}
            getSelectedRowData={getSelectedRowData}
            setSelectedRows={selectRowCount}
            saveData={upDateData}
            onSort={(listType == 'Products') ? handleProductSort : rightFormulary ? handleRightSort : handleSort}
            detailView={detailView}
            deleteDataList={deleteDataList}
            showFixedColumn={showFixedColumn}
            searchKey={searchKey}
            listType={listType}
            formularyView={formularyView}
            formularyLeft={formularyLeft}
            productType={productType}
            fixedFullWidthSize={fixedFullWidthSize}
            activePage={activePage}
            sortType={sortType}
          />
          {(!noPage) && (Array.isArray(data)) && (data.length > 0) && <Pagination
            activePage={activePage}
            itemsCountPerPage={metaData ? metaData.limit : 50}
            totalItemsCount={metaData ? metaData.total : 0}
            getActivePage={getActivePage}
            formularyView={formularyView}
          />}
        </div>
      </>
    }
    
    </>
    }
    {
      data &&listType !== 'Patients'&& <>
        <div className={`${style.loader_parent}`}>
          {/* {loading && <div className={`text-center mt-2 mb-3 ${style.loader_child}`}>
            <img src={Loader} alt="loader" style={{ maxWidth: '50px' }} />
          </div>} */}
          <DataTable
            columns={columns}
            data={data}
            sorting={sorting}
            rowSelect={rowSelect}
            getSelectedRowData={getSelectedRowData}
            setSelectedRows={selectRowCount}
            saveData={upDateData}
            onSort={(listType == 'Products') ? handleProductSort : rightFormulary ? handleRightSort : handleSort}
            detailView={detailView}
            deleteDataList={deleteDataList}
            showFixedColumn={showFixedColumn}
            searchKey={searchKey}
            listType={listType}
            formularyView={formularyView}
            formularyLeft={formularyLeft}
            productType={productType}
            fixedFullWidthSize={fixedFullWidthSize}
            activePage={activePage}
            sortType={sortType}
            filterOnclick={filterOnclick}
          />
          {(!noPage) && (Array.isArray(data)) && (data.length > 0) && <Pagination
            activePage={activePage}
            itemsCountPerPage={metaData ? metaData.limit : 50}
            totalItemsCount={metaData ? metaData.total : 0}
            getActivePage={getActivePage}
            formularyView={formularyView}
          />}
        </div>
      </>
    }
    {
      listModalBox[listType]
    }
    {
      confirmationModal && <Confirmation
        onClose={() => modalClose()}
        title={" "}
      >
        <div className="text-center pr-5 pl-5">
          <p>
            <img src={warning} alt="warnning-icon" />
          </p>
          <p className="pb-3">Are you sure you’d like to delete Items?</p>
          <div className="mb-2">
            <Button
              value="Yes"
              classes={{ className: `btn mr-2 pb-2 pt-2 mr-3 ${style.yes_btn}` }}
              onClick={() => deleteList()}
            />
            <Button
              id="closeId"
              value="No"
              classes={{ className: `btn ml-2 pb-2 pt-2 ${style.no_btn}` }}
              onClick={() => modalClose()}
            />
          </div>
        </div>
      </Confirmation>
    }
    {
      accessError && !modalShow && <AccessErrorModal />
    }
  </>
}

ListView.propTypes = {
  dispatch: PropTypes.func,
};

ListView = connect()(withRouter(ListView));
